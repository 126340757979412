import React from 'react'
import ArrowForwardIosOutlinedIcon from '@mui/icons-material/ArrowForwardIosOutlined';
import {Link} from 'react-router-dom';
// import {useNavigate} from "react-router-dom/dist/index";
import {postAirEmergency, getTodayAirEmergency,getLast24hAirEmergency} from "../../../api/Forms/AirEmergency";
import {getUniversities} from "../../../api/Forms/Partials/Universities";
import {getProvinces} from "../../../api/Forms/Partials/Provinces";
import * as moment from 'jalali-moment';


import swal from 'sweetalert';
import {hideLoading, showLoading,gregorianToJalali} from "../../../utils/global";


class AirEmergency extends React.Component {

    constructor() {
        super()

        this.state = {
            update_id: 0,
            date: moment().locale('fa').format('jYYYY/jM/jD'),
            provice: 0,
            uni: 0,
            missions: 0,
            missions_health_centers: 0,
            sex_man: 0,
            sex_woman: 0,
            nationality_iranian: 0,
            nationality_foreign: 0,
            missions_traffic_dispatch: 0,
            missions_nontraffic_dispatch: 0,
            universities: [],
            provinces: [],
            btn_update_text: 'ذخیره اطلاعات'
        }
    }

    changeStates(event) {
        this.setState({
            [event.target.name]: event.target.value
        });
    }


    componentDidMount() {
        this.loadBasicInfo()
    }


    loadBasicInfo = async () => {
        showLoading()
        const provinces = await getProvinces()
        const universities = await getUniversities()

        if (typeof  provinces.data.rowData !== 'undefined' && typeof  universities.data.rowData !== 'undefined')
            this.setState({
                provinces: provinces.data.rowData,
                universities: universities.data.rowData
            })

        const response = await getTodayAirEmergency();
        if (response && response.data && typeof response.status !== 'undefined' && response.status === 'success' && typeof response.data.id !== 'undefined' && response.data.id > 0)
            await this.edit('today')

        hideLoading()
    }

    edit = async (type = 'today') => {
        showLoading()
        var response = null;
        if (type === 'today')
            response = await getTodayAirEmergency();
        else response = await getLast24hAirEmergency();
        hideLoading()
        if (response && response.data && typeof response.status !== 'undefined' && response.status === 'success') {
            let data = response.data;

            this.setState({
                date: gregorianToJalali(data.date),
                update_id: data.id,
                missions: data.missions,
                missions_health_centers: data.missions_health_centers,
                sex_man: data.sex_man,
                sex_woman: data.sex_woman,
                nationality_iranian: data.nationality_iranian,
                nationality_foreign: data.nationality_foreign,
                missions_traffic_dispatch: data.missions_traffic_dispatch,
                missions_nontraffic_dispatch: data.missions_nontraffic_dispatch,
                btn_update_text: 'ویرایش اطلاعات'
            });

            swal("توجه", 'اطلاعات امروز جایگذاری شد . لطفا اطلاعات را با دقت وارد و در انتها دکمه ویرایش را کلیک نمایید', "info", {
                buttons: false,
                timer: 2000
            });

        }
        else
            swal("ناموفق", 'اطلاعاتی وجود ندارد', "error", {buttons: false, timer: 2000});

    }


    handleSubmit = async (e) => {
        showLoading()
        e.preventDefault();
        const response = await postAirEmergency(this.state.update_id, {
            province: this.state.province,
            date: this.state.date,
            uni: this.state.uni,
            type: this.state.type,
            missions: this.state.missions,
            missions_health_centers: this.state.missions_health_centers,
            sex_man: this.state.sex_man,
            sex_woman: this.state.sex_woman,
            nationality_iranian: this.state.nationality_iranian,
            nationality_foreign: this.state.nationality_foreign,
            missions_traffic_dispatch: this.state.missions_traffic_dispatch,
            missions_nontraffic_dispatch: this.state.missions_nontraffic_dispatch,
        });
        hideLoading()
        if (typeof response.status !== 'undefined' && response.status === "success") {
            if (this.state.update_id) {
                swal("ویرایش موفق", "اطلاعات با موفقیت ویرایش شد", "success", {
                    buttons: false,
                    timer: 3000,
                })
            } else {
                swal("ثبت موفق", "اطلاعات با موفقیت ثبت گردید", "success", {
                    buttons: false,
                    timer: 3000,
                })
            }
        } else {
            swal("ثبت ناموفق", 'خطایی در هنگام ثبت رخ داد', "error", {buttons: false, timer: 2000});
        }
    }


    render() {
        return (
            <>
                <section
                    className="bg-white overflow-auto h-screen my-5 mx-auto a:w-10/12 md:w-7/12 lg:w-6/12 xl:w-5/12 2xl:w-4/12 a:rounded-md">
                    <p></p>
                    <header style={{color: "#2857BD"}}
                            className="flex justify-between items-center border-t-2 border-gray-100 mt-8 py-4 pe-6 shadow-sm shadow-gray-200">
                        <p></p>
                        <p className="font-bold">اورژانس هوایی</p>
                        <Link to="/mainPage/formsList"><ArrowForwardIosOutlinedIcon fontSize="large"/></Link>
                    </header>
                    <main style={{direction: "rtl"}} className="mt-4 btneditbox">
                        <section className="flex justify-center">
                            <button onClick={() => {
                                this.edit('today')
                            }}
                                    style={{backgroundColor: "#2857BD", color: 'white'}}
                                    className="py-2 rounded-md border-2 border-blue-600 w-6/12">ویرایش اطلاعات امروز
                            </button>
                            <button onClick={() => {
                                this.edit('last24h')
                            }}
                                    style={{backgroundColor: "#2857BD", color: 'white'}}
                                    className="py-2  rounded-md border-2 border-blue-600 w-6/12">ویرایش 24 ساعت قبل
                            </button>
                        </section>

                        <form onSubmit={this.handleSubmit} className="px-8 pb-8 space-y-10">
                            <section className="space-y-10">
                                <div className="form-group relative rounded-lg border-2 border-gray-400">
                                    <label className="bg-white text-gray-700 absolute">تاریخ</label>
                                    <input name={'date'} onChange={(e) => {
                                        this.changeStates(e)
                                    }} value={this.state.date} className="w-full focus:outline-none p-2 focus:ring-2"
                                           type="text"/>
                                </div>
                                <div className="form-group relative rounded-lg border-2 border-gray-400">
                                    <label className="bg-white text-gray-700 absolute">نام استان</label>

                                    <select
                                        name={'province'} onChange={(e) => {
                                        this.changeStates(e)
                                    }} value={this.state.province}
                                        className="input-rounded-10 bg-white w-full focus:outline-none p-2 focus:ring-2"
                                    >
                                        <option >انتخاب استان</option>
                                        {this.state.provinces && this.state.provinces.map((province,index ) => {
                                            return (
                                                <option key={'province'+index} value={province.id}>{ province.name }</option>
                                            );
                                        })}
                                    </select>

                                </div>
                                <div className="form-group relative rounded-lg border-2 border-gray-400">
                                    <label className="bg-white text-gray-700 absolute">نام دانشگاه</label>
                                    <select
                                        name={'uni'} onChange={(e) => {
                                        this.changeStates(e)
                                    }} value={this.state.uni}
                                        className="input-rounded-10 bg-white w-full focus:outline-none p-2 focus:ring-2"
                                    >
                                        <option >انتخاب دانشگاه</option>
                                        {this.state.universities && this.state.universities.map(( uni , index) => {

                                            return (
                                                <option key={'uni'+index} value={uni.id}>{ uni.name }</option>
                                            );
                                        })}
                                    </select>

                                </div>

                            </section>
                            <section className="space-y-10">
                                <p style={{color: "#2857BD"}} className="font-bold">نتایج کل ماموریت ها</p>
                                <div className="form-group relative rounded-lg border-2 border-gray-400">
                                    <label className="bg-white text-gray-700 absolute">کل ماموریت</label>
                                    <input name={'missions'} onChange={(e) => {
                                        this.changeStates(e)
                                    }} value={this.state.missions}
                                           className="w-full focus:outline-none p-2 focus:ring-2" type="number"
                                           placeholder='0'/>
                                </div>
                                <div className="form-group relative rounded-lg border-2 border-gray-400">
                                    <label className="bg-white text-gray-700 absolute">کل اعزام به مراکز درمانی</label>
                                    <input name={'missions_health_centers'} onChange={(e) => {
                                        this.changeStates(e)
                                    }} value={this.state.missions_health_centers}
                                           className="w-full focus:outline-none p-2 focus:ring-2" type="number"/>
                                </div>
                            </section>
                            <section className="space-y-10">
                                <p style={{color: "#2857BD"}} className="font-bold">جنس</p>
                                <div className="form-group relative rounded-lg border-2 border-gray-400">
                                    <label className="bg-white text-gray-700 absolute">مرد</label>
                                    <input name={'sex_man'} onChange={(e) => {
                                        this.changeStates(e)
                                    }} value={this.state.sex_man} className="w-full focus:outline-none p-2 focus:ring-2"
                                           type="number" placeholder='0'/>
                                </div>
                                <div className="form-group relative rounded-lg border-2 border-gray-400">
                                    <label className="bg-white text-gray-700 absolute">زن</label>
                                    <input name={'sex_woman'} onChange={(e) => {
                                        this.changeStates(e)
                                    }} value={this.state.sex_woman}
                                           className="w-full focus:outline-none p-2 focus:ring-2" type="number"
                                           placeholder='0'/>
                                </div>
                            </section>
                            <section className="space-y-10">
                                <p style={{color: "#2857BD"}} className="font-bold">ملیت</p>
                                <div className="form-group relative rounded-lg border-2 border-gray-400">
                                    <label className="bg-white text-gray-700 absolute">ایرانی</label>
                                    <input name={'nationality_iranian'} onChange={(e) => {
                                        this.changeStates(e)
                                    }} value={this.state.nationality_iranian}
                                           className="w-full focus:outline-none p-2 focus:ring-2" type="number"
                                           placeholder='0'/>
                                </div>
                                <div className="form-group relative rounded-lg border-2 border-gray-400">
                                    <label className="bg-white text-gray-700 absolute">اتباع خارجی</label>
                                    <input name={'nationality_foreign'} onChange={(e) => {
                                        this.changeStates(e)
                                    }} value={this.state.nationality_foreign}
                                           className="w-full focus:outline-none p-2 focus:ring-2" type="number"
                                           placeholder='0'/>
                                </div>
                            </section>
                            <section className="space-y-10">
                                <p style={{color: "#2857BD"}} className="font-bold">نتایج ماموریت</p>
                                <div className="form-group relative rounded-lg border-2 border-gray-400">
                                    <label className="bg-white text-gray-700 absolute">اعزامی ترافیکی</label>
                                    <input name={'missions_traffic_dispatch'} onChange={(e) => {
                                        this.changeStates(e)
                                    }} value={this.state.missions_traffic_dispatch}
                                           className="w-full focus:outline-none p-2 focus:ring-2" type="number"
                                           placeholder='0'/>
                                </div>
                                <div className="form-group relative rounded-lg border-2 border-gray-400">
                                    <label className="bg-white text-gray-700 absolute">اعزامی غیرترافیکی</label>
                                    <input name={'missions_nontraffic_dispatch'} onChange={(e) => {
                                        this.changeStates(e)
                                    }} value={this.state.missions_nontraffic_dispatch}
                                           className="w-full focus:outline-none p-2 focus:ring-2" type="number"
                                           placeholder='0'/>
                                </div>
                            </section>
                            <section className="flex justify-center">
                                <button style={{color: "#2857BD"}}
                                        className="py-2 rounded-md border-2 border-blue-600 w-6/12">انصراف
                                </button>
                                <input type={'submit'} className="text-white rounded-md ms-5 w-6/12"
                                       style={{backgroundColor: "#2857BD"}} value={this.state.btn_update_text}/>
                            </section>
                        </form>
                    </main>
                </section>
            </>
        );
    }

}


export default AirEmergency
