import React from 'react'
import DrawerLIB from 'react-modern-drawer'
import 'react-modern-drawer/dist/index.css'


class Drawer extends React.Component{

    static toggleDrawer = () => {
        this.setState({ isOpen : !this.state.isOpen })
    }




    constructor(){
        super()


        this.state = {
            isOpen : false
        }
    }

    render(){



        return (
            <>
                <DrawerLIB
                    open={this.state.isOpen}
                    onClose={this.toggleDrawer}
                    direction='right'
                    className='bla bla bla'
                >
                    <div>سلام . تست</div>
                </DrawerLIB>
            </>
        )    }
}

export default Drawer