
import Footer from "../Partials/Footer";
import Carsoaul from "./Carsoaul";
import logo from "../../assets/images/logo.svg";
import Drawer from "../Partials/Drawer"
import MenuIcon from '@mui/icons-material/Menu';
import { Link } from "react-router-dom";
// import $ from 'jquery';
// import {useEffect} from 'react';


const e = new URL('../../assets/images/e.PNG', import.meta.url);
const f = new URL('../../assets/images/f.PNG', import.meta.url);
const g = new URL('../../assets/images/g.PNG', import.meta.url);
const h = new URL('../../assets/images/h.PNG', import.meta.url);
const i = new URL('../../assets/images/i.PNG', import.meta.url);
const j = new URL('../../assets/images/j.PNG', import.meta.url);
const k = new URL('../../assets/images/k.PNG', import.meta.url);
const m = new URL('../../assets/images/m.PNG', import.meta.url);
const n = new URL('../../assets/images/n.PNG', import.meta.url);



const  MainPage = () => {


    // useEffect(()=>{

    //         const targetSlider = document.getElementsByClassName('slider-wrapper')[0];
    //         $('#img_bottom_carsoaul').css('margin-top',targetSlider.clientHeight-28 )
    
    // },[])


    // setTimeout(()=>{
    //     $(document).ready(function(){

        
    //         const targetSlider = document.getElementsByClassName('slider-wrapper')[0];
    //         $('#img_bottom_carsoaul').css('margin-top',targetSlider.clientHeight-28 )
        
    //     })
    
    // },1500)

    return (
        <>
        <section style={{ backgroundColor:'#F9F9F9' }} className=" overflow-auto  mx-auto md:w-8/12 lg:w-7/12 xl:w-6/12 2xl:w-5/12 a:rounded-lg">
            <header className="flex justify-center items-center py-3 sm:py-1" style={{height:90}}>
            <img className="w-3/12 sm:w-2/12 main-header-el-left" src={logo} alt="not found" />
            <p style={{color: "rgb(8, 44, 121)"}} className="font-bold sm:text-xl">صفحه اصلی</p>
            <Link class={'main-header-el-right'}><MenuIcon fontSize="large"/></Link>
            </header>
            <main>
                <section className="flex justify-center">
                    <Carsoaul/>
                </section>
                <section className="mt-7 px-5 grid grid-cols-3 a:grid-cols-2 md:grid-cols-3 gap-6 justify-items-center items-end ">
                    <Link to="/mainPage/treatmentForms"><img style={{transition: "transform .3s"}} className="rounded-lg hover:scale-105" src={e} alt="not found" /></Link>
                    <Link ><img style={{transition: "transform .3s"}} className="rounded-lg hover:scale-105" src={f} alt="not found" /></Link>
                    <Link to="/mainPage/formsList"><img style={{transition: "transform .3s"}} className="  rounded-lg hover:scale-105" src={g} alt="not found" /></Link>
                    <Link><img style={{transition: "transform .3s"}} className="rounded-lg hover:scale-105" src={h} alt="not found" /></Link>
                    <Link><img style={{transition: "transform .3s"}} className="rounded-lg hover:scale-105" src={i} alt="not found" /></Link>
                    <Link><img style={{transition: "transform .3s"}} className="rounded-lg hover:scale-105" src={j} alt="not found" /></Link>
                    <Link><img style={{transition: "transform .3s"}} className="rounded-lg hover:scale-105" src={k} alt="not found" /></Link>
                    <Link><img style={{transition: "transform .3s"}} className="rounded-lg hover:scale-105" src={m} alt="not found" /></Link>
                    <Link><img style={{transition: "transform .3s"}} className="rounded-lg hover:scale-105" src={n} alt="not found" /></Link>
                </section>
            </main>
            <Footer value={4}/>
            <Drawer/>
        </section>
        </>
    );
}

export default MainPage;