import { combineReducers } from 'redux';


import counterReducer from './Mission/mission.reducer';


const rootReducer = combineReducers({

    counter: counterReducer,

});

export default rootReducer;