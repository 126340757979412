const slider1 = new URL('../../assets/images/slider2.jpg',import.meta.url);
const slider2 = new URL('../../assets/images/slider3.jpg',import.meta.url);
// const slider_bottom = new URL('../../assets/images/slider-bottom.png', import.meta.url);
// import $ from 'jquery';


import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';


const Carsoaul = () => {
    return (
    <>
        <Carousel
            dynamicHeight={true}
            showThumbs={false}
            showStatus={false}
            id={'main-slider'}
        >
            <div>
                <img src={slider1} />

            </div>
            <div>
                <img src={slider2} />

            </div>
        </Carousel>

        {/* <img src={slider_bottom} className="img_bottom_carsoaul" id="img_bottom_carsoaul" /> */}
    </>
    );
}

// $(document).ready(function(){


//     var target = document.getElementsByClassName('slider-wrapper')[0];
//     if( target ){
//     var observer = new MutationObserver(function(mutations) {
//         mutations.forEach(function(mutationRecord) {
//             $('#img_bottom_carsoaul').css('margin-top',mutationRecord.target.clientHeight-28 )
//         });
//     });
   
//     observer.observe(target, { attributes : true, attributeFilter : ['style'] });
//     $('#img_bottom_carsoaul').css('margin-top',target.clientHeight-28 )

// }

// })




export default Carsoaul;