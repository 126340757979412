import { api_url } from "../../config/constant"
import {fatoen} from "../../utils/global";
import * as moment from 'jalali-moment';


const getMissions = () => {
    const accessToken = localStorage.getItem('accessToken');
    return fetch(api_url+'/services/missions', {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'Authorization' : accessToken ? 'Bearer '+accessToken : null
        }
    })
        .then(data => data.json())
}


const postMissions = (id,data) => {

    if( typeof data.date !== 'undefined' ) {
        let date_tmp = fatoen(data.date);
        let todayJalali = moment(date_tmp, 'jYYYY/jM/jD');
        data.date = todayJalali.format('YYYY/MM/DD HH:mm:s');
    }
    const accessToken = localStorage.getItem('accessToken');
    if( id ){
        return fetch(api_url + '/services/missions/'+id+'/edit', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': accessToken ? 'Bearer ' + accessToken : null
            },
            body: JSON.stringify(data)
        })
            .then(data => data.json())
    }
    else {
        return fetch(api_url + '/services/missions', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': accessToken ? 'Bearer ' + accessToken : null
            },
            body: JSON.stringify(data)
        })
            .then(data => data.json())
    }
}

const getTodayMission = () => {
    const accessToken = localStorage.getItem('accessToken');
    return fetch(api_url+'/services/missions/today', {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'Authorization' : accessToken ? 'Bearer '+accessToken : null
        }
    })
        .then(data => data.json())
}

const getLast24hMission = () => {
    const accessToken = localStorage.getItem('accessToken');
    return fetch(api_url+'/services/missions/last24h', {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'Authorization' : accessToken ? 'Bearer '+accessToken : null
        }
    })
        .then(data => data.json())
}

export {
    getMissions,postMissions,getTodayMission,getLast24hMission
}