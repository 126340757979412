import b from "./assets/images/b.png";
import { useNavigate } from "react-router-dom";
import './App.css';

function App() {

  const navigate = useNavigate();
  setTimeout(() => {document.getElementById("firstPage").style.display = "none"; navigate("/login")},
   2500);

  return (
    <section id="firstPage" className="">
        <div style={{backgroundColor: "#2857BD"}} className="h-screen flex flex-col justify-center items-center">
        <img src={b} className="font-bold h-32" alt="not found" />
        <p></p>
      </div>

    </section>
  )
}

export default App
