import React from 'react'
import ArrowForwardIosOutlinedIcon from '@mui/icons-material/ArrowForwardIosOutlined';
import {Link} from 'react-router-dom';
// import {useNavigate} from "react-router-dom/dist/index";
import {postMissions, getTodayMission, getLast24hMission} from "../../../api/Forms/Missions";
import {getUniversities} from "../../../api/Forms/Partials/Universities";
import {getProvinces} from "../../../api/Forms/Partials/Provinces";
import * as moment from 'jalali-moment';


import swal from 'sweetalert';
import {gregorianToJalali, hideLoading, showLoading} from "../../../utils/global";


class Mission extends React.Component {

    constructor() {
        super()

        this.state = {
            update_id: 0,
            date: moment().locale('fa').format('jYYYY/jM/jD'),
            provice: 0,
            uni: 0,
            type: 0,
            missions: 0,
            missions_ltreatment: 0,
            missions_health_centers: 0,
            missions_dies: 0,
            sex_man: 0,
            sex_woman: 0,
            nationality_iranian: 0,
            nationality_foreign: 0,
            traffics: 0,
            traffics_health_centers: 0,
            traffics_ltreatment: 0,
            traffics_dies: 0,
            nontraffics: 0,
            nontraffics_health_centers: 0,
            nontraffics_ltreatment: 0,
            nontraffics_dies: 0,
            rnontraffics_poisoning: 0,
            rnontraffics_heart: 0,
            rnontraffics_respiratory: 0,
            rnontraffics_trauma: 0,
            rnontraffics_childbirth: 0,
            rnontraffics_burn: 0,
            rnontraffics_heat: 0,
            rnontraffics_poisoning_other: 0,
            rnontraffics_emergency: 0,
            rnontraffics_other: 0,
            universities: [],
            provinces: [],
            btn_update_text: 'ذخیره اطلاعات'
        }
    }

    changeStates(event) {
        this.setState({
            [event.target.name]: event.target.value
        });
    }


    componentDidMount() {
        this.loadBasicInfo()
    }


    loadBasicInfo = async () => {
        showLoading()
        const provinces = await getProvinces()
        const universities = await getUniversities()

        if (typeof  provinces.data.rowData !== 'undefined' && typeof  universities.data.rowData !== 'undefined')
            this.setState({
                provinces: provinces.data.rowData,
                universities: universities.data.rowData
            })

        const response = await getTodayMission();
        console.log(response.data)
        if( response && response.data && typeof response.status !== 'undefined' && response.status === 'success' && typeof response.data.id !== 'undefined' && response.data.id > 0 )
            await this.edit('today')

        hideLoading()
    }

    edit = async (type = 'today') => {
        showLoading()
        var response = null;
        if (type === 'today')
            response = await getTodayMission();
        else response = await getLast24hMission();
        hideLoading()
        if (response && response.data && typeof response.status !== 'undefined' && response.status === 'success') {
            let data = response.data;

            this.setState({
                date:gregorianToJalali(data.date),
                update_id: data.id,
                missions: data.missions,
                missions_ltreatment: data.missions_ltreatment,
                missions_health_centers: data.missions_health_centers,
                missions_dies: data.missions_dies,
                sex_man: data.sex_man,
                sex_woman: data.sex_woman,
                nationality_iranian: data.nationality_iranian,
                nationality_foreign: data.nationality_foreign,
                traffics: data.traffics,
                traffics_health_centers: data.traffics_health_centers,
                traffics_ltreatment: data.traffics_ltreatment,
                traffics_dies: data.traffics_dies,
                nontraffics: data.nontraffics,
                nontraffics_health_centers: data.nontraffics_health_centers,
                nontraffics_ltreatment: data.nontraffics_ltreatment,
                nontraffics_dies: data.nontraffics_dies,
                rnontraffics_poisoning: data.rnontraffics_poisoning,
                rnontraffics_heart: data.rnontraffics_heart,
                rnontraffics_respiratory: data.rnontraffics_respiratory,
                rnontraffics_trauma: data.rnontraffics_trauma,
                rnontraffics_childbirth: data.rnontraffics_childbirth,
                rnontraffics_burn: data.rnontraffics_burn,
                rnontraffics_heat: data.rnontraffics_heat,
                rnontraffics_poisoning_other: data.rnontraffics_poisoning_other,
                rnontraffics_emergency: data.rnontraffics_emergency,
                rnontraffics_other: data.rnontraffics_other,
                btn_update_text: 'ویرایش اطلاعات'
            });

            swal("توجه", 'اطلاعات امروز جایگذاری شد . لطفا اطلاعات را با دقت وارد و در انتها دکمه ویرایش را کلیک نمایید', "info", {
                buttons: false,
                timer: 2000
            });
        }
        else
            swal("ناموفق", 'اطلاعاتی وجود ندارد', "error", {buttons: false, timer: 2000});

    }


    handleSubmit = async (e) => {
        showLoading()
        e.preventDefault();
        const response = await postMissions(this.state.update_id, {
            province: this.state.province,
            date: this.state.date,
            uni: this.state.uni,
            type: this.state.type,
            missions: this.state.missions,
            missions_ltreatment: this.state.missions_ltreatment,
            missions_health_centers: this.state.missions_health_centers,
            missions_dies: this.state.missions_dies,
            sex_man: this.state.sex_man,
            sex_woman: this.state.sex_woman,
            nationality_iranian: this.state.nationality_iranian,
            nationality_foreign: this.state.nationality_foreign,
            traffics: this.state.traffics,
            traffics_health_centers: this.state.traffics_health_centers,
            traffics_ltreatment: this.state.traffics_ltreatment,
            traffics_dies: this.state.traffics_dies,
            nontraffics: this.state.nontraffics,
            nontraffics_health_centers: this.state.nontraffics_health_centers,
            nontraffics_ltreatment: this.state.nontraffics_ltreatment,
            nontraffics_dies: this.state.nontraffics_dies,
            rnontraffics_poisoning: this.state.rnontraffics_poisoning,
            rnontraffics_heart: this.state.rnontraffics_heart,
            rnontraffics_respiratory: this.state.rnontraffics_respiratory,
            rnontraffics_trauma: this.state.rnontraffics_trauma,
            rnontraffics_childbirth: this.state.rnontraffics_childbirth,
            rnontraffics_burn: this.state.rnontraffics_burn,
            rnontraffics_heat: this.state.rnontraffics_heat,
            rnontraffics_poisoning_other: this.state.rnontraffics_poisoning_other,
            rnontraffics_emergency: this.state.rnontraffics_emergency,
            rnontraffics_other: this.state.rnontraffics_other
        });
        hideLoading()
        if (typeof response.status !== 'undefined' && response.status === "success") {
            if (this.state.update_id) {
                swal("ویرایش موفق", "اطلاعات با موفقیت ویرایش شد", "success", {
                    buttons: false,
                    timer: 3000,
                })
            } else {
                swal("ثبت موفق", "اطلاعات با موفقیت ثبت گردید", "success", {
                    buttons: false,
                    timer: 3000,
                })
            }
        } else {
            swal("ثبت ناموفق", 'خطایی در هنگام ثبت رخ داد', "error", {buttons: false, timer: 2000});
        }
    }


    render() {
        return (
            <>
                <section
                    className="bg-white overflow-auto h-screen  mx-auto a:w-10/12 md:w-7/12 lg:w-6/12 xl:w-5/12 2xl:w-4/12 a:rounded-md">
                    <header style={{color: "#2857BD"}}
                            className="flex justify-center items-center border-t-2 border-gray-100 mt-8 py-4 pe-6 shadow-sm shadow-gray-200">
                        <p></p>
                        <p className="font-bold">ماموریت</p>
                        <Link to="/mainPage/formsList" class={'arrow-back-form'}><ArrowForwardIosOutlinedIcon fontSize="large"/></Link>
                    </header>

                    <main style={{direction: "rtl"}} className="mt-4">



                        <form noValidate onSubmit={this.handleSubmit} className="px-8 pb-8 space-y-10">
                            <section className="flex justify-center">
                                <button type={'button'} onClick={() => {
                                    this.edit('today')
                                }}
                                        style={{backgroundColor: "#2857BD", color: 'white',width:200,fontSize:15}}
                                        className="py-2 rounded-md border-2 border-blue-600 w-6/12">ویرایش اطلاعات امروز
                                </button>
                                <button type={'button'} onClick={() => {
                                    this.edit('last24h')
                                }}
                                        style={{backgroundColor: "#2857BD", color: 'white',marginRight:15,width:200,fontSize:15}}
                                        className="py-2  rounded-md border-2 border-blue-600 w-6/12">ویرایش 24 ساعت قبل
                                </button>
                            </section>

                            <section className="space-y-10">
                                <div className="form-group relative rounded-lg border-2 border-gray-400">
                                    <label
                                        className="bg-white text-gray-700 absolute">تاریخ</label>
                                    <input name={'date'} onChange={(e) => {
                                        this.changeStates(e)
                                    }} value={this.state.date} className="input-rounded-10 w-full focus:outline-none p-2 focus:ring-2"
                                           type="text"/>

                                </div>
                                <div className="form-group relative rounded-lg border-2 border-gray-400">
                                    <label className="bg-white text-gray-700 absolute">نام استان</label>

                                    <select
                                        name={'province'} onChange={(e) => {
                                        this.changeStates(e)
                                    }} value={this.state.province}
                                            className="input-rounded-10 bg-white w-full focus:outline-none p-2 focus:ring-2"
                                            >
                                        <option >انتخاب استان</option>
                                        {this.state.provinces && this.state.provinces.map((province,index ) => {
                                            return (
                                                <option key={'province'+index} value={province.id}>{ province.name }</option>
                                            );
                                        })}
                                    </select>

                                </div>
                                <div className="form-group relative rounded-lg border-2 border-gray-400">
                                    <label className="bg-white text-gray-700 absolute">نام دانشگاه</label>
                                    <select
                                        name={'uni'} onChange={(e) => {
                                        this.changeStates(e)
                                    }} value={this.state.uni}
                                        className="input-rounded-10 bg-white w-full focus:outline-none p-2 focus:ring-2"
                                    >
                                        <option >انتخاب دانشگاه</option>
                                        {this.state.universities && this.state.universities.map(( uni , index) => {

                                            return (
                                                <option key={'uni'+index} value={uni.id}>{ uni.name }</option>
                                            );
                                        })}
                                    </select>

                                </div>
                                <div className="form-group relative rounded-lg border-2 border-gray-400">
                                    <label className="bg-white text-gray-700 absolute">اورژانس/هلال احمر/سپاه
                                        پاسداران</label>

                                    <select value={this.state.type}
                                            className="input-rounded-10 bg-white w-full focus:outline-none p-2 focus:ring-2"
                                            name={'type'} onChange={(e) => {
                                        this.changeStates(e)
                                    }}>
                                        <option value={0}>اورژانس</option>
                                        <option value={1}>هلال احمر</option>
                                        <option value={2}>سپاه پاسداران</option>
                                    </select>
                                </div>
                            </section>
                            <section className="space-y-10">
                                <p style={{color: "#2857BD"}} className="font-bold">نتایج کل ماموریت ها</p>
                                <div className="form-group relative rounded-lg border-2 border-gray-400">
                                    <label className="bg-white text-gray-700 absolute">کل ماموریت</label>
                                    <input name={'missions'} onChange={(e) => {
                                        this.changeStates(e)
                                    }} value={this.state.missions}
                                           className=" input-rounded-10 w-full focus:outline-none p-2 focus:ring-2" type="number"
                                           placeholder='0'/>
                                </div>
                                <div className="form-group relative rounded-lg border-2 border-gray-400">
                                    <label className="bg-white text-gray-700 absolute">کل درمان در محل</label>
                                    <input name={'missions_ltreatment'} onChange={(e) => {
                                        this.changeStates(e)
                                    }} value={this.state.missions_ltreatment}
                                           className="input-rounded-10 w-full focus:outline-none p-2 focus:ring-2" type="number"
                                           placeholder='0'/>
                                </div>
                                <div className="form-group relative rounded-lg border-2 border-gray-400">
                                    <label className="bg-white text-gray-700 absolute">کل اعزام به مراکز درمانی</label>
                                    <input name={'missions_health_centers'} onChange={(e) => {
                                        this.changeStates(e)
                                    }} value={this.state.missions_health_centers}
                                           className="input-rounded-10 w-full focus:outline-none p-2 focus:ring-2" type="number"/>
                                </div>
                                <div className="form-group relative rounded-lg border-2 border-gray-400">
                                    <label className="bg-white text-gray-700 absolute">کل فوت</label>
                                    <input name={'missions_dies'} onChange={(e) => {
                                        this.changeStates(e)
                                    }} value={this.state.missions_dies}
                                           className="input-rounded-10 w-full focus:outline-none p-2 focus:ring-2" type="number"
                                           placeholder='0'/>
                                </div>
                            </section>
                            <section className="space-y-10">
                                <p style={{color: "#2857BD"}} className="font-bold">جنس</p>
                                <div className="form-group relative rounded-lg border-2 border-gray-400">
                                    <label className="bg-white text-gray-700 absolute">مرد</label>
                                    <input name={'sex_man'} onChange={(e) => {
                                        this.changeStates(e)
                                    }} value={this.state.sex_man}
                                           className="input-rounded-10 w-full focus:outline-none p-2 focus:ring-2" type="number"
                                           placeholder='0'/>
                                </div>
                                <div className="form-group relative rounded-lg border-2 border-gray-400">
                                    <label className="bg-white text-gray-700 absolute">زن</label>
                                    <input name={'sex_woman'} onChange={(e) => {
                                        this.changeStates(e)
                                    }} value={this.state.sex_woman}
                                           className="input-rounded-10 w-full focus:outline-none p-2 focus:ring-2" type="number"
                                           placeholder='0'/>
                                </div>
                            </section>
                            <section className="space-y-10">
                                <p style={{color: "#2857BD"}} className="font-bold">ملیت</p>
                                <div className="form-group relative rounded-lg border-2 border-gray-400">
                                    <label className="bg-white text-gray-700 absolute">ایرانی</label>
                                    <input name={'nationality_iranian'} onChange={(e) => {
                                        this.changeStates(e)
                                    }} value={this.state.nationality_iranian}
                                           className="input-rounded-10 w-full focus:outline-none p-2 focus:ring-2" type="number"
                                           placeholder='0'/>
                                </div>
                                <div className="form-group relative rounded-lg border-2 border-gray-400">
                                    <label className="bg-white text-gray-700 absolute">اتباع خارجی</label>
                                    <input name={'nationality_foreign'} onChange={(e) => {
                                        this.changeStates(e)
                                    }} value={this.state.nationality_foreign}
                                           className="input-rounded-10 w-full focus:outline-none p-2 focus:ring-2" type="number"
                                           placeholder='0'/>
                                </div>
                            </section>
                            <section className="space-y-10">
                                <p style={{color: "#2857BD"}} className="font-bold">نتایج ماموریت ترافیکی</p>
                                <div className="form-group relative rounded-lg border-2 border-gray-400">
                                    <label className="bg-white text-gray-700 absolute">کل ماموریت ترافیکی</label>
                                    <input name={'traffics'} onChange={(e) => {
                                        this.changeStates(e)
                                    }} value={this.state.traffics}
                                           className="input-rounded-10 w-full focus:outline-none p-2 focus:ring-2" type="number"
                                           placeholder='0'/>
                                </div>
                                <div className="form-group relative rounded-lg border-2 border-gray-400">
                                    <label className="bg-white text-gray-700 absolute">اعزام به مراکز درمانی</label>
                                    <input name={'traffics_health_centers'} onChange={(e) => {
                                        this.changeStates(e)
                                    }} value={this.state.traffics_health_centers}
                                           className="input-rounded-10 w-full focus:outline-none p-2 focus:ring-2" type="number"
                                           placeholder='0'/>
                                </div>
                                <div className="form-group relative rounded-lg border-2 border-gray-400">
                                    <label className="bg-white text-gray-700 absolute">درمان محل</label>
                                    <input name={'traffics_ltreatment'} onChange={(e) => {
                                        this.changeStates(e)
                                    }} value={this.state.traffics_ltreatment}
                                           className="input-rounded-10 w-full focus:outline-none p-2 focus:ring-2" type="number"
                                           placeholder='0'/>
                                </div>
                                <div className="form-group relative rounded-lg border-2 border-gray-400">
                                    <label className="bg-white text-gray-700 absolute">فوت</label>
                                    <input name={'traffics_dies'} onChange={(e) => {
                                        this.changeStates(e)
                                    }} value={this.state.traffics_dies}
                                           className="input-rounded-10 w-full focus:outline-none p-2 focus:ring-2" type="number"
                                           placeholder='0'/>
                                </div>
                            </section>
                            <section className="space-y-10">
                                <p style={{color: "#2857BD"}} className="font-bold text-xl">ماموریت غیرترافیکی</p>
                                <p style={{color: "#2857BD"}} className="font-bold">نتایج ماموریت غیرترافیکی</p>
                                <div className="form-group relative rounded-lg border-2 border-gray-400">
                                    <label className="bg-white text-gray-700 absolute">کل ماموریت غیرترافیکی</label>
                                    <input name={'nontraffics'} onChange={(e) => {
                                        this.changeStates(e)
                                    }} value={this.state.nontraffics}
                                           className="input-rounded-10 w-full focus:outline-none p-2 focus:ring-2" type="number"
                                           placeholder='0'/>
                                </div>
                                <div className="form-group relative rounded-lg border-2 border-gray-400">
                                    <label className="bg-white text-gray-700 absolute">اعزام به مراکز درمانی</label>
                                    <input name={'nontraffics_health_centers'} onChange={(e) => {
                                        this.changeStates(e)
                                    }} value={this.state.nontraffics_health_centers}
                                           className="input-rounded-10 w-full focus:outline-none p-2 focus:ring-2" type="number"
                                           placeholder='0'/>
                                </div>
                                <div className="form-group relative rounded-lg border-2 border-gray-400">
                                    <label className="bg-white text-gray-700 absolute">درمان محل</label>
                                    <input name={'nontraffics_ltreatment'} onChange={(e) => {
                                        this.changeStates(e)
                                    }} value={this.state.nontraffics_ltreatment}
                                           className="input-rounded-10 w-full focus:outline-none p-2 focus:ring-2" type="number"
                                           placeholder='0'/>
                                </div>
                                <div className="form-group relative rounded-lg border-2 border-gray-400">
                                    <label className="bg-white text-gray-700 absolute">فوت</label>
                                    <input name={'nontraffics_dies'} onChange={(e) => {
                                        this.changeStates(e)
                                    }} value={this.state.nontraffics_dies}
                                           className="input-rounded-10 w-full focus:outline-none p-2 focus:ring-2" type="number"
                                           placeholder='0'/>
                                </div>
                                <p style={{color: "#2857BD"}} className="font-bold">علت ماموریت غیرترافیکی</p>
                                <div className="form-group relative rounded-lg border-2 border-gray-400">
                                    <label className="bg-white text-gray-700 absolute">مسمومیت غذایی</label>
                                    <input name={'rnontraffics_poisoning'} onChange={(e) => {
                                        this.changeStates(e)
                                    }} value={this.state.rnontraffics_poisoning}
                                           className="input-rounded-10 w-full focus:outline-none p-2 focus:ring-2" type="number"
                                           placeholder='0'/>
                                </div>
                                <div className="form-group relative rounded-lg border-2 border-gray-400">
                                    <label className="bg-white text-gray-700 absolute">قلبی</label>
                                    <input name={'rnontraffics_heart'} onChange={(e) => {
                                        this.changeStates(e)
                                    }} value={this.state.rnontraffics_heart}
                                           className="input-rounded-10 w-full focus:outline-none p-2 focus:ring-2" type="number"
                                           placeholder='0'/>
                                </div>
                                <div className="form-group relative rounded-lg border-2 border-gray-400">
                                    <label className="bg-white text-gray-700 absolute">تنفسی</label>
                                    <input name={'rnontraffics_respiratory'} onChange={(e) => {
                                        this.changeStates(e)
                                    }} value={this.state.rnontraffics_respiratory}
                                           className="input-rounded-10 w-full focus:outline-none p-2 focus:ring-2" type="number"
                                           placeholder='0'/>
                                </div>
                                <div className="form-group relative rounded-lg border-2 border-gray-400">
                                    <label className="bg-white text-gray-700 absolute">تروماغیرواقعی</label>
                                    <input name={'rnontraffics_trauma'} onChange={(e) => {
                                        this.changeStates(e)
                                    }} value={this.state.rnontraffics_trauma}
                                           className="input-rounded-10 w-full focus:outline-none p-2 focus:ring-2" type="number"
                                           placeholder='0'/>
                                </div>
                                <div className="form-group relative rounded-lg border-2 border-gray-400">
                                    <label className="bg-white text-gray-700 absolute">گرمازدگی</label>
                                    <input name={'rnontraffics_heat'} onChange={(e) => {
                                        this.changeStates(e)
                                    }} value={this.state.rnontraffics_heat}
                                           className="input-rounded-10 w-full focus:outline-none p-2 focus:ring-2" type="number"
                                           placeholder='0'/>
                                </div>
                                <div className="form-group relative rounded-lg border-2 border-gray-400">
                                    <label className="bg-white text-gray-700 absolute">زایمان در حضور تکنسین</label>
                                    <input name={'rnontraffics_childbirth'} onChange={(e) => {
                                        this.changeStates(e)
                                    }} value={this.state.rnontraffics_childbirth}
                                           className="input-rounded-10 w-full focus:outline-none p-2 focus:ring-2" type="number"
                                           placeholder='0'/>
                                </div>
                                <div className="form-group relative rounded-lg border-2 border-gray-400">
                                    <label className="bg-white text-gray-700 absolute">سوختگی</label>
                                    <input name={'rnontraffics_burn'} onChange={(e) => {
                                        this.changeStates(e)
                                    }} value={this.state.rnontraffics_burn}
                                           className="input-rounded-10 w-full focus:outline-none p-2 focus:ring-2" type="number"
                                           placeholder='0'/>
                                </div>
                                <div className="form-group relative rounded-lg border-2 border-gray-400">
                                    <label className="bg-white text-gray-700 absolute">اورژانس زنان</label>
                                    <input name={'rnontraffics_emergency'} onChange={(e) => {
                                        this.changeStates(e)
                                    }} value={this.state.rnontraffics_emergency}
                                           className="input-rounded-10 w-full focus:outline-none p-2 focus:ring-2" type="number"
                                           placeholder='0'/>
                                </div>
                                <div className="form-group relative rounded-lg border-2 border-gray-400">
                                    <label className="bg-white text-gray-700 absolute">سایر مسمومیت ها</label>
                                    <input name={'rnontraffics_poisoning_other'} onChange={(e) => {
                                        this.changeStates(e)
                                    }} value={this.state.rnontraffics_poisoning_other}
                                           className="input-rounded-10 w-full focus:outline-none p-2 focus:ring-2" type="number"
                                           placeholder='0'/>
                                </div>
                                <div className="form-group relative rounded-lg border-2 border-gray-400">
                                    <label className="bg-white text-gray-700 absolute">سایر</label>
                                    <input name={'rnontraffics_other'} onChange={(e) => {
                                        this.changeStates(e)
                                    }} value={this.state.rnontraffics_other}
                                           className="input-rounded-10 w-full focus:outline-none p-2 focus:ring-2" type="number"
                                           placeholder='0'/>
                                </div>
                            </section>
                            <section className="flex justify-center">
                                <Link to="/mainPage/formsList" className="py-2 text-center rounded-md border-2 border-blue-600 w-6/12"  style={{color: "#2857BD"}}>انصراف
                                </Link>
                                <input type={'submit'} className="text-white rounded-md ms-5 w-6/12"
                                       style={{backgroundColor: "#2857BD"}} value={this.state.btn_update_text}/>
                            </section>
                        </form>
                    </main>
                </section>
            </>
        );
    }

}


export default Mission
