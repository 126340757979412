import App from '../App.jsx'
import ErrorPage from '../views/ErrorPages/ErrorPage.jsx'
import Login from '../views/Auth/Login.jsx'
import MainPage from '../views/Dashboard/MainPage.jsx'
import Profile from '../views/Profile/Profile.jsx'
import EditInformation from '../views/Profile/EditInformation.jsx'
import FormsList from '../views/Forms/FormsList.jsx'
import SpecialIncident from '../views/Forms/SpecialIncident/SpecialIncident.jsx'
import Mission from '../views/Forms/Mission/Mission.jsx'
import AirEmergency from '../views/Forms/AirEmergency/AirEmergency.jsx'
import AmbulanceBus from '../views/Forms/AmbulanceBus/AmbulanceBus.jsx'
import Heatstroke from '../views/Forms/Heatstroke/Heatstroke.jsx'
import Motorlance from '../views/Forms/Motorlance/Motorlance.jsx'
import TreatmentForms from '../views/Forms/TreatmentForms'
import HospitalEmergency from '../views/Forms/HospitalEmergency/HospitalEmergency'
import Injured from '../views/Forms/Injureds/Injureds.jsx'
import Test from '../views/Test'


import { createBrowserRouter } from "react-router-dom";

const router = createBrowserRouter([
    {
        path: "/",
        element: <App/>,
        errorElement: <ErrorPage/>
    },
    {
        path:"/login",
        element: <Login/>,
        errorElement: <ErrorPage/>
    },
    {
        path: "/mainPage",
        element: <MainPage/>,
        errorElement: <ErrorPage/>
    },
    {
        path: "/mainPage/profile",
        element: <Profile/>,
        errorElement: <ErrorPage/>
    },
    {
        path: "/mainPage/profile/edit",
        element: <EditInformation/>,
        errorElement: <ErrorPage/>
    },
    {
        path: "/mainPage/formsList",
        element: <FormsList/>,
        errorElement: <ErrorPage/>
    },
    {
        path: "/mainPage/formList/specialIncident",
        element: <SpecialIncident/>,
        errorElement: <ErrorPage/>
    },
    {
        path: "/mainPage/formList/mission",
        element: <Mission/>,
        errorElement: <ErrorPage/>
    },
    {
        path: "/mainPage/formList/airEmergency",
        element: <AirEmergency/>,
        errorElement: <ErrorPage/>
    },
    {
        path: "/mainPage/formList/ambulanceBus",
        element: <AmbulanceBus/>,
        errorElement: <ErrorPage/>
    },
    {
        path: "/mainPage/formList/motorlance",
        element: <Motorlance/>,
        errorElement: <ErrorPage/>
    },
    {
        path: "/mainPage/formList/injureds",
        element: <Injured/>,
        errorElement: <ErrorPage/>
    },
    {
        path: "/mainPage/formList/heatstroke",
        element: <Heatstroke/>,
        errorElement: <ErrorPage/>
    },
    {
        path: "/test",
        element: <Test/>,
        errorElement: <ErrorPage/>
    },
    {
        path: "/mainPage/treatmentForms",
        element: <TreatmentForms/>,
        errorElement: <ErrorPage/>
    },
    {
        path: "/mainPage/hospitalemergency",
        element: <HospitalEmergency/>,
        errorElement: <ErrorPage/>
    },
]);

export default router;