import {useState} from 'react';

import logo from "../../assets/images/logo.svg";
import {Link} from "react-router-dom";
import swal from 'sweetalert';
import { useNavigate } from "react-router-dom";
// import { useSelector, useDispatch } from "react-redux";
import { login } from "../../api/Auth/Auth"
import {hideLoading, showLoading} from "../../utils/global";

const Login = () => {


    const navigate = useNavigate();
    const [national_code, setNationalCode] = useState();
    const [password, setPassword] = useState();

    const handleSubmit = async e => {
        e.preventDefault();
        showLoading()
        const response = await login({
            national_code,
            password
        });
        if ('token' in response) {
            swal("ورود موفق", "کاربر گرامی شما با موفقیت به سیستم وارد شدید", "success", {
                buttons: false,
                timer: 2000,
            })
                .then(() => {
                    localStorage.setItem('accessToken', response['token']);
                    navigate("/mainPage");
                });
        } else {
            swal("ورود ناموفق", 'نام کاربری یا رمز عبور شما اشتباه است', "error",{buttons: false,timer: 2000});
        }
        hideLoading()
    }


    return (
        <>
            <section
                className="bg-white h-screen overflow-auto  mx-auto a:w-10/12 md:w-7/12 lg:w-6/12 xl:w-5/12 2xl:w-4/12 a:rounded-md">
                <header className="flex flex-col justify-center items-center space-y-8 pt-8">
                    <img src={logo} alt="not found" width={150}/>
                    <p style={{color: "#2857BD"}} className="font-bold text-2xl">ورود به سامانه</p>
                </header>
                <main className="mt-14 px-5" style={{direction: "rtl"}}>
                    <form noValidate onSubmit={handleSubmit}>
                        <div>
                            <p className="text-xl">شماره ملی / کد ملی</p>
                            <input
                                className="rounded-md border-2 ps-5 py-2 border-gray-400 mt-3 w-full focus:outline-none focus:ring-4"
                                type="text" placeholder="شماره ملی / کد ملی"
                                onChange={e => setNationalCode(e.target.value)}/>
                        </div>
                        <div className="mt-7">
                            <p className="text-xl">گذرواژه</p>
                            <input
                                className="rounded-md border-2 ps-5 py-2 border-gray-400 mt-3 w-full focus:outline-none focus:ring-4"
                                type="password" placeholder="گذرواژه" onChange={e => setPassword(e.target.value)}/>
                        </div>

                        <input
                            style={{backgroundColor: "#F1C40F", color: "D9D9D9"}}
                            type="submit"
                            className="mt-14 py-2 text-center text-xl text-gray-900 rounded-md block w-full m-auto shadow-lg hover:shadow-gray-500"
                        value={'ورود'}
                        />
                        <p className="mt-10 text-lg">ورود به اپلیکیشن به منزله قبول <Link className="text-blue-700">قوانین
                            و مقررات</Link> است.</p>
                    </form>
                </main>
                <footer className="flex justify-center mt-16">
                    <img src={logo} alt="not found"/>
                </footer>
            </section>
        </>
    );
}


export default Login;