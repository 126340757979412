import { api_url } from "../../config/constant"

const login = (data) => {
    return fetch(api_url+'/login', {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(data)
    })
        .then(data => data.json())
}


// const register = () => {
//
// }


export {
    login
}