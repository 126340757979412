import * as moment from 'jalali-moment';


function fatoen(el){
    const regex = /[۰-۹]/g

    let result = el.replace(regex, function (w) {
            return String.fromCharCode(w.charCodeAt(0) - 1728)
        }
    )
    return result
}

function showLoading() {
    document.getElementById('loading_box').classList.remove('hidden')
}
function hideLoading() {
    document.getElementById('loading_box').classList.add('hidden')
}

function jalaliToGregorian (date) {
    let date_tmp = fatoen(date);
    let todayJalali = moment(date_tmp, 'jYYYY/jM/jD');
    return todayJalali.format('YYYY/MM/DD');
}

function gregorianToJalali(date) {
    let todayJalali = moment(date, 'YYYY/M/D');
    return todayJalali.format('jYYYY/jMM/jDD');
}

function jalaliToGregorianWithTime (date) {
    let date_tmp = fatoen(date);
    let todayJalali = moment(date_tmp, 'jYYYY/jM/jD');
    return todayJalali.format('YYYY/MM/DD HH:mm:s');
}

function gregorianToJalaliWithTime (date) {
    let todayJalali = moment(date, 'YYYY/M/D');
    return todayJalali.format('jYYYY/jMM/jDD HH:mm:s');
}


export {
    fatoen,showLoading,hideLoading,jalaliToGregorian,jalaliToGregorianWithTime,gregorianToJalali,gregorianToJalaliWithTime
}