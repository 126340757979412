const ErrorPage = () => {
    return (
        <>
        <section className="h-screen text-bold text-2xl flex justify-center items-center">
            Error: Not Found 404...
        </section>
        </>
    );
}

export default ErrorPage;