import React from 'react'
import ArrowForwardIosOutlinedIcon from '@mui/icons-material/ArrowForwardIosOutlined';
import {Link} from 'react-router-dom';
// import {useNavigate} from "react-router-dom/dist/index";
import {
    postHospitalEmergency,
    getTodayHospitalEmergency,
    getLast24hHospitalEmergency
} from "../../../api/Forms/HospitalEmergency";
import {getUniversities} from "../../../api/Forms/Partials/Universities";
import {getProvinces} from "../../../api/Forms/Partials/Provinces";
import * as moment from 'jalali-moment';


import swal from 'sweetalert';
import {hideLoading, showLoading, gregorianToJalali} from "../../../utils/global";


class HospitalEmergency extends React.Component {

    constructor() {
        super()

        this.state = {
            update_id: 0,
            date: moment().locale('fa').format('jYYYY/jM/jD'),
            provice: 0,
            uni: 0,
            type: 0,
            treatment_type: 0,
            total_clients: 0,
            sex_man: 0,
            sex_woman: 0,
            nationality_iranian: 0,
            nationality_foreign: 0,
            treatment_discharge: 0,
            hospitalization: 0,
            ospitalization_icu: 0,
            surgery_elective: 0,
            surgery_emergency: 0,
            health_centers: 0,
            refferal_reasons: 0,
            traffics_trauma: 0,
            nontraffic_trauma: 0,
            respiratory: 0,
            heart: 0,
            digestive: 0,
            cerebral: 0,
            heatstroke: 0,
            poisonings: 0,
            insurance_tamin: 0,
            insurance_khadamat_darmani: 0,
            insurance_salamat: 0,
            insurance_visitor: 0,
            insurance_other: 0,
            universities: [],
            provinces: [],
            btn_update_text: 'ذخیره اطلاعات'
        }
    }

    changeStates(event) {
        this.setState({
            [event.target.name]: event.target.value
        });
    }


    componentDidMount() {
        this.loadBasicInfo()
    }


    loadBasicInfo = async () => {
        showLoading()
        const provinces = await getProvinces()
        const universities = await getUniversities()

        if (typeof  provinces.data.rowData !== 'undefined' && typeof  universities.data.rowData !== 'undefined')
            this.setState({
                provinces: provinces.data.rowData,
                universities: universities.data.rowData
            })

        const response = await getTodayHospitalEmergency();
        if (response && response.data && typeof response.status !== 'undefined' && response.status === 'success' && typeof response.data.id !== 'undefined' && response.data.id > 0)
            await this.edit('today')

        hideLoading()
    }

    edit = async (type = 'today') => {
        showLoading()
        var response = null;
        if (type === 'today')
            response = await getTodayHospitalEmergency();
        else response = await getLast24hHospitalEmergency();
        hideLoading()
        if (response && response.data && typeof response.status !== 'undefined' && response.status === 'success') {
            let data = response.data;

            this.setState({
                date: gregorianToJalali(data.date),
                update_id: data.id,
                treatment_type : data.treatment_type,
                total_clients : data.total_clients,
                sex_man : data.sex_man,
                sex_woman : data.sex_woman,
                nationality_iranian : data.nationality_iranian,
                nationality_foreign : data.nationality_foreign,
                treatment_discharge : data.treatment_discharge,
                hospitalization : data.hospitalization,
                ospitalization_icu : data.ospitalization_icu,
                surgery_elective : data.surgery_elective,
                surgery_emergency : data.surgery_emergency,
                health_centers : data.health_centers,
                refferal_reasons : data.refferal_reasons,
                traffics_trauma : data.traffics_trauma,
                nontraffic_trauma : data.nontraffic_trauma,
                respiratory : data.respiratory,
                heart : data.heart,
                digestive : data.digestive,
                cerebral : data.cerebral,
                heatstroke : data.heatstroke,
                poisonings : data.poisonings,
                insurance_tamin : data.insurance_tamin,
                insurance_khadamat_darmani : data.insurance_khadamat_darmani,
                insurance_salamat : data.insurance_salamat,
                insurance_visitor : data.insurance_visitor,
                insurance_other : data.insurance_other,
                btn_update_text: 'ویرایش اطلاعات'
            });

            swal("توجه", 'اطلاعات امروز جایگذاری شد . لطفا اطلاعات را با دقت وارد و در انتها دکمه ویرایش را کلیک نمایید', "info", {
                buttons: false,
                timer: 2000
            });

        }
        else
            swal("ناموفق", 'اطلاعاتی وجود ندارد', "error", {buttons: false, timer: 2000});

    }


    handleSubmit = async (e) => {
        showLoading()
        e.preventDefault();
        const response = await postHospitalEmergency(this.state.update_id, {
            province: this.state.province,
            date: this.state.date,
            uni: this.state.uni,
            treatment_type : this.state.treatment_type,
            total_clients : this.state.total_clients,
            sex_man : this.state.sex_man,
            sex_woman : this.state.sex_woman,
            nationality_iranian : this.state.nationality_iranian,
            nationality_foreign : this.state.nationality_foreign,
            treatment_discharge : this.state.treatment_discharge,
            hospitalization : this.state.hospitalization,
            ospitalization_icu : this.state.ospitalization_icu,
            surgery_elective : this.state.surgery_elective,
            surgery_emergency : this.state.surgery_emergency,
            health_centers : this.state.health_centers,
            refferal_reasons : this.state.refferal_reasons,
            traffics_trauma : this.state.traffics_trauma,
            nontraffic_trauma : this.state.nontraffic_trauma,
            respiratory : this.state.respiratory,
            heart : this.state.heart,
            digestive : this.state.digestive,
            cerebral : this.state.cerebral,
            heatstroke : this.state.heatstroke,
            poisonings : this.state.poisonings,
            insurance_tamin : this.state.insurance_tamin,
            insurance_khadamat_darmani : this.state.insurance_khadamat_darmani,
            insurance_salamat : this.state.insurance_salamat,
            insurance_visitor : this.state.insurance_visitor,
            insurance_other : this.state.insurance_other,
        });
        hideLoading()
        if (typeof response.status !== 'undefined' && response.status === "success") {
            if (this.state.update_id) {
                swal("ویرایش موفق", "اطلاعات با موفقیت ویرایش شد", "success", {
                    buttons: false,
                    timer: 3000,
                })
            } else {
                swal("ثبت موفق", "اطلاعات با موفقیت ثبت گردید", "success", {
                    buttons: false,
                    timer: 3000,
                })
            }
        } else {
            swal("ثبت ناموفق", 'خطایی در هنگام ثبت رخ داد', "error", {buttons: false, timer: 2000});
        }
    }


    render() {
        return (
            <>
                <section
                    className="bg-white overflow-auto h-screen my-5 mx-auto a:w-10/12 md:w-7/12 lg:w-6/12 xl:w-5/12 2xl:w-4/12 a:rounded-md">
                    <p></p>
                    <header style={{color: "#2857BD"}}
                            className="flex justify-between items-center border-t-2 border-gray-100 mt-8 py-4 pe-6 shadow-sm shadow-gray-200">
                        <p></p>
                        <p className="font-bold">اورژانس بیمارستانی</p>
                        <Link to="/mainPage/treatmentForms"><ArrowForwardIosOutlinedIcon fontSize="large"/></Link>
                    </header>
                    <main style={{direction: "rtl"}} className="mt-4 btneditbox">
                        <section className="flex justify-center">
                            <button onClick={() => {
                                this.edit('today')
                            }}
                                    style={{backgroundColor: "#2857BD", color: 'white'}}
                                    className="py-2 rounded-md border-2 border-blue-600 w-6/12">ویرایش اطلاعات امروز
                            </button>
                            <button onClick={() => {
                                this.edit('last24h')
                            }}
                                    style={{backgroundColor: "#2857BD", color: 'white'}}
                                    className="py-2  rounded-md border-2 border-blue-600 w-6/12">ویرایش 24 ساعت قبل
                            </button>
                        </section>

                        <form onSubmit={this.handleSubmit} className="px-8 pb-8 space-y-10">
                            <section className="space-y-10">
                                <div className="form-group relative rounded-lg border-2 border-gray-400">
                                    <label className="bg-white text-gray-700 absolute">تاریخ</label>
                                    <input name={'date'} onChange={(e) => {
                                        this.changeStates(e)
                                    }} value={this.state.date} className="w-full focus:outline-none p-2 focus:ring-2"
                                           type="text"/>
                                </div>
                                <div className="form-group relative rounded-lg border-2 border-gray-400">
                                    <label className="bg-white text-gray-700 absolute">نام استان</label>

                                    <select
                                        name={'province'} onChange={(e) => {
                                        this.changeStates(e)
                                    }} value={this.state.province}
                                        className="input-rounded-10 bg-white w-full focus:outline-none p-2 focus:ring-2"
                                    >
                                        <option>انتخاب استان</option>
                                        {this.state.provinces && this.state.provinces.map((province, index) => {
                                            return (
                                                <option key={'province' + index}
                                                        value={province.id}>{province.name}</option>
                                            );
                                        })}
                                    </select>

                                </div>
                                <div className="form-group relative rounded-lg border-2 border-gray-400">
                                    <label className="bg-white text-gray-700 absolute">نام دانشگاه</label>
                                    <select
                                        name={'uni'} onChange={(e) => {
                                        this.changeStates(e)
                                    }} value={this.state.uni}
                                        className="input-rounded-10 bg-white w-full focus:outline-none p-2 focus:ring-2"
                                    >
                                        <option>انتخاب دانشگاه</option>
                                        {this.state.universities && this.state.universities.map((uni, index) => {

                                            return (
                                                <option key={'uni' + index} value={uni.id}>{uni.name}</option>
                                            );
                                        })}
                                    </select>
                                </div>

                                <div className="form-group relative rounded-lg border-2 border-gray-400">
                                    <label className="bg-white text-gray-700 absolute">نوع</label>

                                    <select value={this.state.type}
                                            className="input-rounded-10 bg-white w-full focus:outline-none p-2 focus:ring-2"
                                            name={'type'} onChange={(e) => {
                                        this.changeStates(e)
                                    }}>
                                        <option value={0}>ثابت</option>
                                        <option value={1}>سیار</option>
                                    </select>
                                </div>

                                <div className="form-group relative rounded-lg border-2 border-gray-400">
                                    <label className="bg-white text-gray-700 absolute">دانشگاهی/نظانی/تامین اجتماعی</label>

                                    <select value={this.state.treatment_type}
                                            className="input-rounded-10 bg-white w-full focus:outline-none p-2 focus:ring-2"
                                            name={'treatment_type'} onChange={(e) => {
                                        this.changeStates(e)
                                    }}>
                                        <option value={0}>دانشگاهی</option>
                                        <option value={1}>نظامی</option>
                                        <option value={2}>تامین اجتماعی</option>
                                        <option value={3}>سایر</option>
                                    </select>
                                </div>

                            </section>
                            <section className="space-y-10">
                                <p style={{color: "#2857BD"}} className="font-bold">نتایج کل مراجعین</p>
                                <div className="form-group relative rounded-lg border-2 border-gray-400">
                                    <label className="bg-white text-gray-700 absolute">کل مراجعین</label>
                                    <input name={'total_clients'} onChange={(e) => {
                                        this.changeStates(e)
                                    }} value={this.state.total_clients} className="w-full focus:outline-none p-2 focus:ring-2" type="number"
                                           placeholder='0'/>
                                </div>
                            </section>
                            <section className="space-y-10">
                                <p style={{color: "#2857BD"}} className="font-bold">جنس</p>
                                <div className="form-group relative rounded-lg border-2 border-gray-400">
                                    <label className="bg-white text-gray-700 absolute">مرد</label>
                                    <input name={'sex_man'} onChange={(e) => {
                                        this.changeStates(e)
                                    }} value={this.state.sex_man} className="w-full focus:outline-none p-2 focus:ring-2" type="number"
                                           placeholder='0'/>
                                </div>
                                <div className="form-group relative rounded-lg border-2 border-gray-400">
                                    <label className="bg-white text-gray-700 absolute">زن</label>
                                    <input name={'sex_woman'} onChange={(e) => {
                                        this.changeStates(e)
                                    }} value={this.state.sex_woman} className="w-full focus:outline-none p-2 focus:ring-2" type="number"
                                           placeholder='0'/>
                                </div>
                            </section>
                            <section className="space-y-10">
                                <p style={{color: "#2857BD"}} className="font-bold">ملیت</p>
                                <div className="form-group relative rounded-lg border-2 border-gray-400">
                                    <label className="bg-white text-gray-700 absolute">ایرانی</label>
                                    <input name={'nationality_iranian'} onChange={(e) => {
                                        this.changeStates(e)
                                    }} value={this.state.nationality_iranian} className="w-full focus:outline-none p-2 focus:ring-2" type="number"
                                           placeholder='0'/>
                                </div>
                                <div className="form-group relative rounded-lg border-2 border-gray-400">
                                    <label className="bg-white text-gray-700 absolute">اتباع خارجی</label>
                                    <input name={'nationality_foreign'} onChange={(e) => {
                                        this.changeStates(e)
                                    }} value={this.state.nationality_foreign} className="w-full focus:outline-none p-2 focus:ring-2" type="number"
                                           placeholder='0'/>
                                </div>
                            </section>
                            <section className="space-y-10">
                                <div className="form-group relative rounded-lg border-2 border-gray-400">
                                    <label className="bg-white text-gray-700 absolute">کل ماموریت غیرترافیکی</label>
                                    <input name={'total_clients'} onChange={(e) => {
                                        this.changeStates(e)
                                    }} value={this.state.total_clients} className="w-full focus:outline-none p-2 focus:ring-2" type="number"
                                           placeholder='0'/>
                                </div>
                                <div className="form-group relative rounded-lg border-2 border-gray-400">
                                    <label className="bg-white text-gray-700 absolute">درمان و ترخیص</label>
                                    <input name={'treatment_discharge'} onChange={(e) => {
                                        this.changeStates(e)
                                    }} value={this.state.treatment_discharge} className="w-full focus:outline-none p-2 focus:ring-2" type="number"
                                           placeholder='0'/>
                                </div>
                                <div className="form-group relative rounded-lg border-2 border-gray-400">
                                    <label className="bg-white text-gray-700 absolute">بستری</label>
                                    <input name={'hospitalization'} onChange={(e) => {
                                        this.changeStates(e)
                                    }} value={this.state.hospitalization} className="w-full focus:outline-none p-2 focus:ring-2" type="number"
                                           placeholder='0'/>
                                </div>
                                <div className="form-group relative rounded-lg border-2 border-gray-400">
                                    <label className="bg-white text-gray-700 absolute">بستری در مراقبت های ویژه</label>
                                    <input name={'ospitalization_icu'} onChange={(e) => {
                                        this.changeStates(e)
                                    }} value={this.state.ospitalization_icu} className="w-full focus:outline-none p-2 focus:ring-2" type="number"
                                           placeholder='0'/>
                                </div>
                            </section>
                            <section className="space-y-10">
                                <p style={{color: "#2857BD"}} className="font-bold text-xl">کل جراحی</p>
                                <div className="form-group relative rounded-lg border-2 border-gray-400">
                                    <label className="bg-white text-gray-700 absolute">جراحی الکتیو</label>
                                    <input name={'surgery_elective'} onChange={(e) => {
                                        this.changeStates(e)
                                    }} value={this.state.surgery_elective} className="w-full focus:outline-none p-2 focus:ring-2" type="number"
                                           placeholder='0'/>
                                </div>
                                <div className="form-group relative rounded-lg border-2 border-gray-400">
                                    <label className="bg-white text-gray-700 absolute">جراحی اورژانسی</label>
                                    <input name={'surgery_emergency'} onChange={(e) => {
                                        this.changeStates(e)
                                    }} value={this.state.surgery_emergency} className="w-full focus:outline-none p-2 focus:ring-2" type="number"
                                           placeholder='0'/>
                                </div>

                                <div className="form-group relative rounded-lg border-2 border-gray-400">
                                    <label className="bg-white text-gray-700 absolute">اعزام فوق تخصصی/تخصصی/مبدا</label>

                                    <select value={this.state.health_centers}
                                            className="input-rounded-10 bg-white w-full focus:outline-none p-2 focus:ring-2"
                                            name={'health_centers'} onChange={(e) => {
                                        this.changeStates(e)
                                    }}>
                                        <option value={0}>اعزام مراکز درمانی فوق تخصصی</option>
                                        <option value={1}>تخصصی</option>
                                        <option value={2}>شهر های مبدا</option>
                                    </select>
                                </div>

                                <p style={{color: "#2857BD"}} className="font-bold text-xl">علل مراجعه</p>

                                <div className="form-group relative rounded-lg border-2 border-gray-400">
                                    <label className="bg-white text-gray-700 absolute">تروما ترافیکی</label>
                                    <input name={'traffics_trauma'} onChange={(e) => {
                                        this.changeStates(e)
                                    }} value={this.state.traffics_trauma} className="w-full focus:outline-none p-2 focus:ring-2" type="number"
                                           placeholder='0'/>
                                </div>
                                <div className="form-group relative rounded-lg border-2 border-gray-400">
                                    <label className="bg-white text-gray-700 absolute">تروما غیرترافیکی</label>
                                    <input name={'nontraffic_trauma'} onChange={(e) => {
                                        this.changeStates(e)
                                    }} value={this.state.nontraffic_trauma} className="w-full focus:outline-none p-2 focus:ring-2" type="number"
                                           placeholder='0'/>
                                </div>
                                <div className="form-group relative rounded-lg border-2 border-gray-400">
                                    <label className="bg-white text-gray-700 absolute">تنفسی</label>
                                    <input name={'respiratory'} onChange={(e) => {
                                        this.changeStates(e)
                                    }} value={this.state.respiratory} className="w-full focus:outline-none p-2 focus:ring-2" type="number"
                                           placeholder='0'/>
                                </div>
                                <div className="form-group relative rounded-lg border-2 border-gray-400">
                                    <label className="bg-white text-gray-700 absolute">قلبی</label>
                                    <input name={'heart'} onChange={(e) => {
                                        this.changeStates(e)
                                    }} value={this.state.heart} className="w-full focus:outline-none p-2 focus:ring-2" type="number"
                                           placeholder='0'/>
                                </div>
                                <div className="form-group relative rounded-lg border-2 border-gray-400">
                                    <label className="bg-white text-gray-700 absolute">گوارشی</label>
                                    <input name={'digestive'} onChange={(e) => {
                                        this.changeStates(e)
                                    }} value={this.state.digestive} className="w-full focus:outline-none p-2 focus:ring-2" type="number"
                                           placeholder='0'/>
                                </div>
                                <div className="form-group relative rounded-lg border-2 border-gray-400">
                                    <label className="bg-white text-gray-700 absolute">مغزی</label>
                                    <input name={'cerebral'} onChange={(e) => {
                                        this.changeStates(e)
                                    }} value={this.state.cerebral} className="w-full focus:outline-none p-2 focus:ring-2" type="number"
                                           placeholder='0'/>
                                </div>
                                <div className="form-group relative rounded-lg border-2 border-gray-400">
                                    <label className="bg-white text-gray-700 absolute">گرمازدگی</label>
                                    <input name={'heatstroke'} onChange={(e) => {
                                        this.changeStates(e)
                                    }} value={this.state.heatstroke} className="w-full focus:outline-none p-2 focus:ring-2" type="number"
                                           placeholder='0'/>
                                </div>

                                <div className="form-group relative rounded-lg border-2 border-gray-400">
                                    <label className="bg-white text-gray-700 absolute">مسمومیت ها</label>
                                    <input name={'poisonings'} onChange={(e) => {
                                        this.changeStates(e)
                                    }} value={this.state.poisonings} className="w-full focus:outline-none p-2 focus:ring-2" type="number"
                                           placeholder='0'/>
                                </div>

                                <p style={{color: "#2857BD"}} className="font-bold text-xl">نوع بیمه</p>


                                <div className="form-group relative rounded-lg border-2 border-gray-400">
                                    <label className="bg-white text-gray-700 absolute">تامین اجتماعی</label>
                                    <input name={'insurance_tamin'} onChange={(e) => {
                                        this.changeStates(e)
                                    }} value={this.state.insurance_tamin} className="w-full focus:outline-none p-2 focus:ring-2" type="number"
                                           placeholder='0'/>
                                </div>
                                <div className="form-group relative rounded-lg border-2 border-gray-400">
                                    <label className="bg-white text-gray-700 absolute">خدمات درمانی</label>
                                    <input name={'insurance_khadamat_darmani'} onChange={(e) => {
                                        this.changeStates(e)
                                    }} value={this.state.insurance_khadamat_darmani} className="w-full focus:outline-none p-2 focus:ring-2" type="number"
                                           placeholder='0'/>
                                </div>
                                <div className="form-group relative rounded-lg border-2 border-gray-400">
                                    <label className="bg-white text-gray-700 absolute">سلامت</label>
                                    <input name={'insurance_salamat'} onChange={(e) => {
                                        this.changeStates(e)
                                    }} value={this.state.insurance_salamat} className="w-full focus:outline-none p-2 focus:ring-2" type="number"
                                           placeholder='0'/>
                                </div>

                                <div className="form-group relative rounded-lg border-2 border-gray-400">
                                    <label className="bg-white text-gray-700 absolute">زائر</label>
                                    <input name={'insurance_visitor'} onChange={(e) => {
                                        this.changeStates(e)
                                    }} value={this.state.insurance_visitor} className="w-full focus:outline-none p-2 focus:ring-2" type="number"
                                           placeholder='0'/>
                                </div>
                                <div className="form-group relative rounded-lg border-2 border-gray-400">
                                    <label className="bg-white text-gray-700 absolute">سایر</label>
                                    <input name={'insurance_other'} onChange={(e) => {
                                        this.changeStates(e)
                                    }} value={this.state.insurance_other} className="w-full focus:outline-none p-2 focus:ring-2" type="number"
                                           placeholder='0'/>
                                </div>
                            </section>

                            <section className="flex justify-center">
                                <button style={{color: "#2857BD"}}
                                        className="py-2 rounded-md border-2 border-blue-600 w-6/12">انصراف
                                </button>
                                <input type={'submit'} className="text-white rounded-md ms-5 w-6/12"
                                       style={{backgroundColor: "#2857BD"}} value={this.state.btn_update_text}/>
                            </section>
                        </form>
                    </main>
                </section>
            </>
        );
    }

}


export default HospitalEmergency
