import { api_url } from "../../config/constant"
import {fatoen} from "../../utils/global";
import * as moment from 'jalali-moment';


const getAmbulanceBus = () => {
    const accessToken = localStorage.getItem('accessToken');
    return fetch(api_url+'/services/busambulance', {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'Authorization' : accessToken ? 'Bearer '+accessToken : null
        }
    })
        .then(data => data.json())
}


const postAmbulanceBus = (id,data) => {

    if( typeof data.date !== 'undefined' ) {
        let date_tmp = fatoen(data.date);
        let todayJalali = moment(date_tmp, 'jYYYY/jM/jD');
        data.date = todayJalali.format('YYYY/MM/DD HH:mm:s');
    }

    const accessToken = localStorage.getItem('accessToken');
    if( id ){
        return fetch(api_url + '/services/busambulance/'+id+'/edit', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': accessToken ? 'Bearer ' + accessToken : null
            },
            body: JSON.stringify(data)
        })
            .then(data => data.json())
    }
    else {
        return fetch(api_url + '/services/busambulance', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': accessToken ? 'Bearer ' + accessToken : null
            },
            body: JSON.stringify(data)
        })
            .then(data => data.json())
    }
}

const getTodayAmbulanceBus = () => {
    const accessToken = localStorage.getItem('accessToken');
    return fetch(api_url+'/services/busambulance/today', {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'Authorization' : accessToken ? 'Bearer '+accessToken : null
        }
    })
        .then(data => data.json())
}

const getLast24hAmbulanceBus = () => {
    const accessToken = localStorage.getItem('accessToken');
    return fetch(api_url+'/services/busambulance/last24h', {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'Authorization' : accessToken ? 'Bearer '+accessToken : null
        }
    })
        .then(data => data.json())
}

export {
    getAmbulanceBus,postAmbulanceBus,getTodayAmbulanceBus,getLast24hAmbulanceBus
}
