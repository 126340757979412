import React from 'react'
import ReactDOM from 'react-dom/client'
import './index.css'
import {RouterProvider} from "react-router-dom";
import router from './Router'
import {Provider} from 'react-redux';
import store from './redux/store';
import logo from "./assets/images/logo.svg";



ReactDOM.createRoot(document.getElementById('root')).render(
    <Provider store={store}>
        <React.StrictMode>

            <RouterProvider router={router}/>
            <div className="loading_box hidden" id={'loading_box'}>
                <img src={logo} alt="Loading..."/>
            </div>
        </React.StrictMode>
    </Provider>
)
