import { INCREMENT, DECREMENT } from './mission.types';


export const increaseCounter = () => {



    return {

        type: INCREMENT,

    };

};

export const decreaseCounter = () => {

    return {

        type: DECREMENT,

    };

};