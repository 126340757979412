import { api_url } from "../../../config/constant"


const getProvinces = () => {
    const accessToken = localStorage.getItem('accessToken');
    return fetch(api_url+'/services/provinces', {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'Authorization' : accessToken ? 'Bearer '+accessToken : null
        }
    })
        .then(data => data.json())
}


export {
    getProvinces
}